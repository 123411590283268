import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import AnimatingLogo from "./animatingLogo";
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide, } from "swiper/react";
import Link from './link'

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

const ParallaxBanner = ({ image, gallery, home, text, title, caption, version }) => {
  useEffect(() => {
    if (window.innerWidth < 768) {
      if (document.querySelector('.parallax--banner.home--version')) {
        document.querySelector('.parallax--banner.home--version').style.paddingTop = window.innerHeight + 'px';
      }
      document.querySelector('.parallax-image').style.height = document.querySelector('.parallax--banner').offsetHeight + 80 + 'px';
    }
    setInterval(() => {
      var banner = document.querySelector('.parallax--banner .parallax-image');
      if (banner) {
        if (window.pageYOffset > window.innerHeight * .5) {
          banner?.classList.add('fade-out');
        } else {
          banner?.classList.remove('fade-out');
        }
        if (document.querySelector('.bg--light')) {
          if (window.pageYOffset > document.querySelector('.parallax--banner').offsetHeight + 60) {
            document.querySelector('.masthead').classList.add('is-dark')
          } else {
            document.querySelector('.masthead').classList.remove('is-dark')
          }
        }
      }
    }, 500)
  }, [])
  if (version == null && home == null) {
    return (
      <div className='parallax--banner version--2 pos-rel flex align-bottom'>
        <div className="w-100 p40  m-pt10 mta flex m-wrap">
          <h1 className="m0 h2 content--bolded m-mt0 m-mb100" dangerouslySetInnerHTML={{ '__html': text }}></h1>
          <p className='m0 mla mta mb10 m-100 m-m0'>{caption}</p> 
        </div>
        <GatsbyImage className="parallax-image" image={image.gatsbyImageData} alt={text} />
      </div>
    )
  } else if (home) {
    return (
      <div id='top-banner' className='parallax--banner pos-rel home--version'>
        <div className='z-1000 home--logo flex gap-20 m-hide'>
          <Link className='inverse ul-link link' to='/projects'>Projects</Link> 
          <Link className='inverse ul-link link' to='/about'>About</Link>
          <Link className='inverse ul-link link' to='/services'>Services</Link>
          <Link className='inverse ul-link link' to='/perspectiv'>Perspectiv</Link>
          <Link className='inverse ul-link link' to='/contact'>Contact</Link> 
        </div>
        <div className="max-1600 p40 pb160  m-pt10">
          <h1 className="m0 h2 mt40 content--bolded m-mt0" dangerouslySetInnerHTML={{ '__html': text }} />
        </div>
        {(gallery ? <Gallery gallery={gallery} />:'')}
        <GatsbyImage className="parallax-image" image={image.gatsbyImageData} alt={text} />
      </div>
    )
  } else {
    return (
      <div id='top-banner' className='parallax--banner version--2 pos-rel flex flex-wrap'>
        <div className="p40  m-pt10">
          <h1 className="m0 h2 mt40 content--bolded m-mt0" dangerouslySetInnerHTML={{ '__html': text }}></h1>
        </div>
        <div className="mta w-100 p40 text-center m-mb40"> 
          <p className="m0 h1 uppercase">{title}</p>
        </div>
        <GatsbyImage className="parallax-image" image={image.gatsbyImageData} alt={text} />
      </div>
    )
  }
}
export default ParallaxBanner

const Gallery = ({gallery}) => {
  return (
    <div className='bg-image'>
      <Swiper className='swiper-container bg-image' simulateTouch={true} modules={[EffectFade, Autoplay]} effect="fade" speed={750} fadeEffect={{ crossFade: true }} loop={true} autoplay={{ delay: 5000, disableOnInteraction: false}}>
        {gallery.map((image, index) => (
          <SwiperSlide key={index}>
            <GatsbyImage image={image.gatsbyImageData} alt={`Hero Image ${index}`} className="bg-image" />
          </SwiperSlide>
        ))}
      </Swiper> 
    </div>
  )
}
